import React from 'react';
import styled from 'styled-components';
import loadable from "@loadable/component"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import '../../styles/globals.css';
import { Link, graphql } from 'gatsby';
import { MetaComponent } from '../../components';
import {
  Button,
  SassyText,
  Title,
  Section,
  Text,
  Divider,
  SectionWrapper,
} from '../../components/core';
import { useMixpanel } from '../../context/MixpanelContext';
import useHindiLangFont from '../../hooks/useHindiLangFont';
import { LeadFormProvider } from '../../context/LeadFormContext';

import FireArt from '../../assets/images/fire_sfsp.svg';
import RiotsArt from '../../assets/images/riots_sfsp.svg';
import LandslideArt from '../../assets/images/landslide_sfsp.svg';
import PlaneArt from '../../assets/images/plane_sfsp.svg';
import SprinklerArt from '../../assets/images/sprinkler_sfsp.svg';
import LightningArt from '../../assets/images/lightning_sfsp.svg';
import StormArt from '../../assets/images/storm_sfsp.svg';
import RocketArt from '../../assets/images/rocket_sfsp.svg';
import CrashedCarArt from '../../assets/images/crashed_car_sfsp.svg';

import Fridge from '../../assets/images/fridge.svg';
import Earthquake from '../../assets/images/earthquake.svg';
import Professional from '../../assets/images/professional.svg';
import StockRemoval from '../../assets/images/stock_removal.svg';
import Umbrella from '../../assets/images/umbrella.svg';
import ForrestFire from '../../assets/images/forrest_fire.svg';
import DebrisRemoval from '../../assets/images/debris_removal.svg';
import WaterGun from '../../assets/images/watergun.svg';
import Bullet from '../../assets/images/bullet.svg';

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const HelpSection = loadable(() => import("../../components/HelpSection"));
const PolicyHeader = loadable(() => import("../../components/PolicyHeader"));
const VideoSection = loadable(() => import("../../components/VideoSection"));

const SFSP = () => {
  const {hindiBlack, hindiBold, hindiRegular} = useHindiLangFont()
  const {t} = useTranslation(['sfsp', 'common'])
  const mixpanel = useMixpanel()
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName = "") => {
    if (mxEventName) mixpanel.track(mxEventName)
    handleQuoteModalOpen()
  }

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Standard Fire and Special Perils (SFSP) Policy - Verak"
        description="Protect your business assets from Fire and Allied Perils through a SFSP Insurance policy. Get a quote."
        image="sfsp"
        url="fire/sfsp"
      />
      <Navbar active="products" />
      <PolicyHeader
        acronym="SFSP"
        title={
          <>
            <SassyText className={hindiBlack}>{t('HeroSection.Title')} </SassyText>(SFSP)
          </>
        }
        content={
          <div className={hindiRegular}> 
            <Trans i18nKey="HeroSection.Desc1">
              Standard Fire and Special Perils (SFSP) is a 
              <strong> Fire and Allied Perils Insurance product </strong> 
              available for businesses in India. It offers coverage against a
              large number of risks such as fire, lightning, aircraft damage,
              riots, floods, among others
            </Trans>
            <br />
            <br />
            <span>
              <Trans i18nKey="HeroSection.Desc2">
                Up until 1st April 2021, SFSP was the only Fire and Allied Perils
                product offered by insurance companies to businesses and homes.
                However, the IRDAI (Insurance Regulatory and Development Authority
                of India) introduced 3 new products to cater to SMEs, MSMEs, and
                homeowners.
              </Trans>
            </span>
          </div>
        }
        subcontent={
          <span className={hindiRegular}>
            <Trans i18nKey="HeroSection.Desc3" style={{fontWeight: "700"}}>
              SFSP now insures only those businesses whose total insurable assets
              at any 
              <SassyText>one location are worth more than ₹50 Crore</SassyText>
            </Trans>
          </span>
        }
      />
      <VideoSection />

      {/* Coverage Section */}
      <Section background="#FFF7F1">
        <SectionWrapper>
          <Title className={hindiBlack}>
            <Trans i18nKey="WhatSFSPCovers.Title">
              What does<SassyText> SFSP </SassyText>cover?
            </Trans>
          </Title>
          <Left>
            <Text
              fontWeight="bold"
              mfontSize="20px"
              fontSize="24px"
              style={{ marginTop: '45px' }}
            >
              <SassyText>
                <strong className={hindiBold}>{t('WhatSFSPCovers.StandardCover.Title')}</strong>
              </SassyText>
            </Text>
            <Row>
              <Column>
                <Container>
                  <FireArt className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.StandardCover.FireExplosion')}
                  </Text>
                </Container>
                <Container>
                  <RiotsArt className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.StandardCover.RSMD')}
                  </Text>
                </Container>

                <Container>
                  <LandslideArt className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.StandardCover.Landslides')}
                  </Text>
                </Container>
                <Container>
                  <PlaneArt className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.StandardCover.AircraftDamage')}
                  </Text>
                </Container>
                <Container>
                  <SprinklerArt className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.StandardCover.Leakage')}
                  </Text>
                </Container>
              </Column>
              <Column>
                <Container>
                  <LightningArt className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.StandardCover.Lightning')}
                  </Text>
                </Container>
                <Container>
                  <StormArt className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px">
                    {t('WhatSFSPCovers.StandardCover.STFI')}
                  </Text>
                </Container>
                <Container>
                  <RocketArt className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.StandardCover.MissileTesting')}
                  </Text>
                </Container>
                <Container>
                  <CrashedCarArt className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.StandardCover.ImpactDamage')}
                  </Text>
                </Container>
              </Column>
            </Row>
            <Text
              fontWeight="bold"
              mfontSize="20px"
              fontSize="24px"
              style={{ marginTop: '45px' }}
            >
              <SassyText type="secondary">
                <strong className={hindiBold}>A{t('WhatSFSPCovers.AddonCovers.Title')}</strong>
              </SassyText>
            </Text>
            <Row>
              <Column>
                <Container>
                  <Earthquake className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.AddonCovers.Earthquake')}
                  </Text>
                </Container>
                <Container>
                  <Fridge className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.AddonCovers.StockDeterioration')}
                  </Text>
                </Container>
                <Container>
                  <WaterGun className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.AddonCovers.Terrorism')}
                  </Text>
                </Container>
                <Container>
                  <Professional className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.AddonCovers.ProfessionalFees')}
                  </Text>
                </Container>
              </Column>
              <Column>
                <Container>
                  <ForrestFire className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.AddonCovers.ForestFire')}
                  </Text>
                </Container>
                <Container>
                  <StockRemoval className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.AddonCovers.Temporary')}
                  </Text>
                </Container>
                <Container>
                  <Umbrella className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.AddonCovers.Spoilage')}
                  </Text>
                </Container>
                <Container>
                  <DebrisRemoval className="icon" />
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    {t('WhatSFSPCovers.AddonCovers.Debris')}
                  </Text>
                </Container>
              </Column>
            </Row>
          </Left>
        </SectionWrapper>
      </Section>

      {/* Why opt for policy section */}
      <Section background="#ffffff">
        <SectionWrapper>
          <Title className={hindiBlack}>
            <Trans i18nKey="WhyChooseSFSP.Title">
              Why opt for an<SassyText> SFSP </SassyText>policy?
            </Trans>
          </Title>
          <Left>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyChooseSFSP.ExhaustiveCoverage.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                    {t('WhyChooseSFSP.ExhaustiveCoverage.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyChooseSFSP.CoverAllBusinesses.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                    {t('WhyChooseSFSP.CoverAllBusinesses.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyChooseSFSP.AdditionalCovers.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiBold}
                  >
                    {t('WhyChooseSFSP.AdditionalCovers.Desc')}
                  </Text>
                </li>
              </ul>
            </Container>
            <Text fontSize="16px" mfontSize="16px" className={hindiRegular}>
              <Trans i18nKey="WhyChooseSFSP.LearnAboutSFSP">
                Learn about
                <strong> Standard Fire & Special Perils (SFSP) </strong>
                in detail 
                <a href="https://blog.verak.in/product-explainers-standard-fire-and-special-perils-insurance-amp/">
                  <SassyText>HERE</SassyText>
                </a>
              </Trans>
            </Text>
            <Text
              fontSize="16px"
              mfontSize="16px"
              style={{ margin: '40px 0px' }}
              className={hindiRegular}
            >
              <Trans i18nKey="WhyChooseSFSP.OtherOptionsText">
                If your business’s total insurable assets at 
                <strong className={hindiBold}> any one location are worth less than ₹50 Crore</strong>,
                you may want to consider opting for a 
              </Trans>
              <Link to="/fire/blus">
                <SassyText type="secondary" className={hindiRegular}>
                  {t('BLUSFullForm')} (BLUS)
                </SassyText>
              </Link>{' '}
              , {t('WhyChooseSFSP.Or')} {t('WhyChooseSFSP.A')}{' '}
              <Link to="/fire/bsus">
                <SassyText type="secondary" className={hindiRegular}>
                  {t('BSUSFullForm')} (BSUS)
                </SassyText>
              </Link>{' '}
              {t('WhyChooseSFSP.InsurancePolicy')}!
            </Text>
            <Button wide label={t('CTALabels.GetAQuote')} onClick={() => handleGetQuoteClick("Clicked-Down - 'Get a Quote' - SFSP LP")} className={hindiBold} />
          </Left>
        </SectionWrapper>
      </Section>
      <HelpSection />
      <Divider />
      <Footer />
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["sfsp", "index", "common", "navbar", "footer", "getQuoteModal", "helpSection", "videoSection"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Left = styled.div`
  text-align: left;

  @media screen and (max-width: 768px) {
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  margin: 20px 0px;

  .bullet {
    margin-left: -20px;
    margin-right: 35px;
  }

  li {
    color: #999999;
    margin-left: 24px;
    list-style: none;
  }

  .icon {
    margin: 5px 35px 0px 0px;
  }
  .space-left {
    padding-left: 35px;
  }

  .space-right {
    padding-right: 35px;
  }
`;

export default SFSP;
